<template>
  <div class="edit-div">
    <label for="name">Name</label>
    <input type="text" id="name" v-model="name" required />
    <br />
    <label for="code">Place</label>
    <input type="text" id="code" v-model="code" required />
    <br />
    <label for="startDate">Start Date</label>
    <input type="date" id="startDate" v-model="startDate" required />
    <br />
    <label for="endDate">endDate</label>
    <input type="date" id="endDate" v-model="endDate" required />
    <br />
    <label for="maxUsageNumber">Maximum Usage Number</label>
    <input type="number" id="maxUsageNumber" v-model="maxUsageNumber" />
    <br />
    <label for="newPrice">New Price</label>
    <input type="number" id="newPrice" v-model="newPrice" step="0.01" />
    <br />
    <label for="whitelist">Client Whitelist</label>
    <br />
    <div class="whitelist-edit">
      <div id="whitelist" v-for="(user, userIndex) in whitelist" :key="user.id">
        <label for="email">Email</label>
        <input type="email" v-model="user.email" id="email" required />
        <button
          @click="whitelist.splice(userIndex, 1)"
          class="remove-whitelist-button"
        >
          X
        </button>
      </div>
    </div>
    <button
      @click="whitelist.push({ id: '', email: '' })"
      class="add-whitelist-button"
    >
      +
    </button>
    <br />
    <button
      id="confirmation-button"
      @click="submitEdit"
      :disabled="checkForm"
      class="save-button"
    >
      Save
    </button>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "VoucherDisplay",
  data() {
    return {
      name: "",
      code: "",
      startDate: "",
      endDate: "",
      clients: [],
      maxUsageNumber: null,
      newPrice: "",
      edit: false,
      whitelist: [],
      testvar: 99,
    };
  },
  computed: {
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      if (!this.code) {
        return true;
      }
      if (!this.startDate) {
        return true;
      }
      if (!this.endDate) {
        return true;
      }
      if (!this.newPrice) {
        return true;
      }
      for (let i = 0; i < this.whitelist.length; i++) {
        if (
          !new RegExp(
            "[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(.[a-z0-9-]+)*"
          ).test(this.whitelist[i].email)
        ) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    submitEdit: async function () {
      try {
        let newData = {
          name: this.name,
          code: this.code,
          startDate: this.startDate,
          endDate: this.endDate,
          maxUsageNumber: this.maxUsageNumber,
          users: [],
          whitelist: this.whitelist,
          newPrice: this.newPrice,
        };
        let res = await HandleApi.postVoucher(newData);
        if (res.status == 200) {
          this.$router.push("/voucher/" + res.data);
        }
      } catch (err) {
        window.console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.edit-div {
  text-align: center;
  margin-top: 20px;
  line-height: 40px;
}

.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.whitelist-edit {
  display: inline-block;
}

.app-container {
  display: inline-block;
}

.remove-whitelist-button {
  margin: 10px;
  color: white;
  height: 21px;
  width: 21px;
  text-align: center;
  background-color: #ff3a3a;
  border-radius: 30px;
  border: none;
  padding: 0;
}

.add-whitelist-button {
  background-color: rgb(55, 187, 248);
  color: white;
  border: none;
  border-radius: 3px;
  height: 20px;
  font-weight: bold;
  width: 30px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}
</style>
